import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import PartnerHeader from '../../components/slides/offers/Offer/PartnerHeader';
import PartnerFeatures from '../../components/slides/offers/Offer/PartnerFeatures';
import Testimonials from '../../components/slides/offers/Offer/Testimonials';

const Partner = ({ pageContext }) => {
  const partner = {
    trialUrl: 'https://start.deadlinefunnel.com/create-monthly',
    ctaUrl: 'https://start.deadlinefunnel.com/stealth-selling',
    wistiaHash: 'j1v4z8r1bi',
    thankYouPage: false
  }

  return (
    <Layout ctaUrl={partner.trialUrl}>
      <SEO title="Stealth Selling | Deadline Funnel" />
      <PartnerHeader partner={partner} />
      <PartnerFeatures ctaUrl={partner.ctaUrl} />
      <Testimonials />
    </Layout>
  )
};

export default Partner;
